import React, { useEffect, useRef, useState } from 'react';
import useStyles from './styles';
import MaterialTable from 'material-table';
import moment from 'moment';
import _ from 'lodash';
import Charts from '../charts/Charts';
// Material UI components
import {
  Grid,
  Paper,
  MuiThemeProvider,
  createMuiTheme,
  MenuItem,
  Button,
  Popper,
  ClickAwayListener,
  MenuList,
  Modal,
  Backdrop,
  CircularProgress,
  IconButton,
  Typography,
  Divider,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  TextField,
  FormControl,
  InputLabel,
  Fade,
  Menu,
  Select,
  useMediaQuery,
} from '@material-ui/core';
// Icons
import { PictureAsPdf as PdfIcon, Check, Edit, Close } from '@material-ui/icons';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import pdfIcon from '../logos/pdfIcon.png';
import logo from '../logos/logo_kamioun.png';
import redLogo from '../logos/logo_kamioun_red.png';
// redux
import {
  generatePickList,
  getAgentReport,
  getOrders,
  getSalesList,
  printOrderSummaries,
  switchFilter,
  updateOrderDetails,
  updateOrderStatus,
  generateOrderSummary,
} from '../../redux/actions/orders';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearOrderProducts,
  getOrderProducts,
  updateProductsToShip,
} from '../../redux/actions/products';
import { useConfirm } from 'material-ui-confirm';

import Scrollbars from 'react-custom-scrollbars';
import { useHistory } from 'react-router-dom';
import { getManufacturers } from '../../redux/actions/manufacturers';
import { getAgents } from '../../redux/actions/retailers';
import { useLocalStorage } from '../../hooks/useLocalStorage';

const status = [
  { value: 'open', field: 'open', next: 'valid', prev: false, cancel: true },
  { value: 'valid', field: 'valid', next: 'shipped', prev: 'open', cancel: true },
  { value: 'shipped', field: 'ready to ship', next: false, prev: 'valid', cancel: true },
  { value: 'unpaid', field: 'unpaid', next: 'delivered', prev: false, cancel: false },
  { value: 'delivered', field: 'delivered & paid', next: 'archived', prev: false, cancel: false },
  { value: 'archived', field: 'archived', next: false, prev: false, cancel: false },
  { value: 'failed', field: 'failed', next: false, prev: false, cancel: false },
  { value: 'closed', field: 'closed', next: false, prev: false, cancel: false },
  { value: 'allStatuses', field: 'all statuses', next: false, prev: false, cancel: false },
];

const deliveryStatus = {
  completed: {
    color: '#D9FFC1',
  },
  failed: { color: '#FFAEAE' },
  pending: { color: '#FFF1C1' },
  online: { color: '#87CEEB' },
};

export default function Dashboard() {
  let classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#2e466e',
      },
      secondary: {
        main: '#e0b20e',
      },
    },
    typography: {
      fontFamily: 'Roboto',
    },
  });

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // Global State
  const ordersState = useSelector((state) => state).ordersReducer;
  const productsState = useSelector((state) => state).productsReducer;
  const uiState = useSelector((state) => state).uiActionsReducer;

  // Local State
  const [open, setOpen] = useState(false);
  const [orderData, setOrderData] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState({
    value: 'open',
    field: 'open',
    next: 'valid',
    prev: false,
    cancel: true,
  });
  const [rowsData, setRowsData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [editOption, setEditOption] = useState(false);
  const [deliveryDate, setDeliveryDate] = useState(false);
  const [modalAgentReportOpen, setModalAgentReportOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(0);
  const [selectedGroupItems, setSelectedGroupItems] = useState([]);
  const [selectedGroupName, setSelectedGroupName] = useState(0);
  const [reportStartDate, setReportStartDate] = useState('');
  const [reportEndDate, setReportEndDate] = useState('');
  const [shippedValues, setShippedValues] = useState([{ sku: '', value: '' }]);
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
  const menuOpen = Boolean(menuAnchorEl);
  const anchorRef = useRef(null);
  const tableRef = useRef(null);
  const [inSearch, setInSearch] = useState(false);
  const [seledtedFilter, setSelectedFilter] = useLocalStorage('selectedFilter');

  useEffect(() => {
    setSelectedFilter(ordersState?.selectedFilter);
    dispatch(getOrders(ordersState?.selectedFilter));
  }, [dispatch, ordersState.selectedFilter]);

  const handleClose = (event) => {
    setOpen(false);
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const handleSelectionChange = (rows) => {
    setRowsData(rows);
  };

  const handleStatusChange = (state) => {
    dispatch(switchFilter(state));
    setSelectedStatus(status.find((item) => item.value === state));
    setOpen(false);
  };

  const handleModalOpen = (order) => {
    setModalOpen(true);
    dispatch(getOrderProducts(order.entity_id));
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setEditOption(false);
    dispatch(clearOrderProducts());
  };

  const handleEditOpen = () => {
    setEditOption(true);
  };

  const handleEditSave = () => {
    setEditOption(false);
    const delivery_date = deliveryDate
      ? deliveryDate
      : orderData.extension_attributes.delivery_date;
    let deliveryDateChanged =
      delivery_date.localeCompare(orderData.extension_attributes.delivery_date) != 0;
    if (deliveryDateChanged) {
      updateOrderDetails(orderData, deliveryDate, (data) => {
        const copy = orderData;
        if (deliveryDateChanged) {
          copy.extension_attributes.delivery_date = data.extension_attributes.delivery_date;
        }
        setOrderData(copy);
      });
    }
    setDeliveryDate(false);
    const shippedInput = shippedValues.find((item) => item.value.length > 0);
    if (shippedInput) {
      const products = shippedValues.filter((item) => item.sku !== '');
      dispatch(updateProductsToShip(orderData, products));
      const copy = orderData;
      copy.items.map((item) => {
        products.map((prod) => (item.sku === prod.sku ? (item.weight = prod.value) : item.weight));
      });
      setOrderData(copy);
      setShippedValues([{ sku: '', value: '' }]);
    }
  };

  const handleDeliveryDateChange = (day) => {
    setDeliveryDate(day);
  };

  const handleShippedInputChange = (sku, value, qty) => {
    if (value > qty) {
      value = qty;
    } else if (value < 0) {
      value = 0;
    }
    let shipped = shippedValues.find((item) => item.sku === sku);
    if (!shipped) {
      const obj = {
        sku: sku,
        value: value,
      };
      setShippedValues((shippedValues) => [...shippedValues, obj]);
    } else {
      shipped.value = value;
      let newValue = shippedValues.filter((item) => item.sku !== shipped.sku);
      newValue.push(shipped);
      setShippedValues(shippedValues);
    }
  };

  const getTotal = (row) => {
    let sansTax = row.qty_ordered * row.price;
    let tax = (sansTax / 100) * row.tax_percent;
    return sansTax + tax;
  };

  const handleModalAgentReportClose = () => {
    setModalAgentReportOpen(false);
    //**Init report generation info */
    setSelectedGroup(0);
    setSelectedGroupItems([]);
    setSelectedGroupName(0);
    setReportStartDate('');
    setReportEndDate('');
  };

  const handleReportsDownload = (selectedGroup, selectedPerson, startDate, endDate) => {
    const selected = selectedPerson === 0 ? '' : selectedPerson;
    if (startDate && endDate) {
      if (selectedGroup === 0) {
        dispatch(
          getSalesList(startDate, endDate, selected, (data) => {
            let properties =
              'height=' +
              window.innerHeight +
              ',width=' +
              window.innerWidth +
              ',' +
              'scrollbars=yes,status=yes';
            window.open(data, properties);
          }),
        );
      } else {
        dispatch(
          getAgentReport(startDate, endDate, selected, (data) => {
            let properties =
              'height=' +
              window.innerHeight +
              ',width=' +
              window.innerWidth +
              ',' +
              'scrollbars=yes,status=yes';
            window.open(data, properties);
          }),
        );
      }
    } else {
      alert('START and END date are required for generating the report');
    }
  };

  const handleGenratePicklist = () => {
    setMenuAnchorEl(null);
    if (rowsData.length === 0) {
      alert('Please select an order');
    } else {
      dispatch(generatePickList(rowsData));
    }
  };

  const handlePrintOrderSummaries = () => {
    setMenuAnchorEl(null);
    if (rowsData.length === 0) {
      alert('Please select an order');
    } else {
      printOrderSummaries(rowsData);
    }
  };

  const handleMenuItemClick = (status) => {
    setMenuAnchorEl(null);
    if (status === 'canceled') {
      const message =
        rowsData.length > 1
          ? 'You want to cancel these orders: '
          : 'You want to cancel this order: ';
      const ordersIds = rowsData.map((order) => {
        return order.extension_attributes.kamioun_order_id;
      });
      confirm({
        description: message + ordersIds,
        title: <Typography style={{ color: 'red', fontSize: 30 }}>Are you sure ?</Typography>,
        confirmationText: 'YES I am sure',
        cancellationText: "NO I don't know what im doing",
      })
        .then(() => {
          dispatch(updateOrderStatus(rowsData, status));
        })
        .catch(() => {});
    } else {
      dispatch(updateOrderStatus(rowsData, status));
    }
  };

  const columnHeader = {
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.71',
    letterSpacing: '0.1px',
    color: '#263238',
    paddingLeft: 40,
  };

  return (<div>
       <Charts />
       <Grid container spacing={5} className={classes.parentContainer}>
      <Grid item xs={12} sm={12} md={6} lg={12} xl={6}>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={modalAgentReportOpen}
          onClose={handleModalAgentReportClose}
          className={classes.orderDetails}
          closeAfterTransition
        >
          <div className={classes.paperReports}>
            <Typography variant="h6" className={classes.reportTitle}>
              Download report
            </Typography>
            <Typography variant="h6" className={classes.sectionReport}>
              Target
            </Typography>
            <div className={classes.formContainer}>
              <FormControl
                variant="outlined"
                style={{ width: '45%' }}
                className={classes.sectionReport}
              >
                <InputLabel
                  required
                  style={{
                    backgroundColor: 'white',
                    paddingLeft: 2,
                    paddingRight: 2,
                  }}
                  htmlFor="Group"
                >
                  Group
                </InputLabel>
                <Select
                  value={selectedGroup}
                  onChange={(evt) => {
                    setSelectedGroup(evt.target.value);
                    setSelectedGroupName(0);
                    /***When group changes the names list changes as well */
                    if (evt.target.value === 0) {
                      //load suppliers list
                      dispatch(
                        getManufacturers((data) => {
                          setSelectedGroupItems(data.items);
                        }),
                      );
                    } else {
                      //load Agents list
                      dispatch(
                        getAgents((data) => {
                          setSelectedGroupItems(data.items);
                        }),
                      );
                    }
                  }}
                >
                  <MenuItem value={0} style={{ textAlign: 'center' }}>
                    Supplier
                  </MenuItem>
                  <MenuItem value={1} style={{ textAlign: 'center' }}>
                    Agent
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl
                variant="outlined"
                style={{ width: '45%' }}
                className={classes.sectionReport}
              >
                <InputLabel
                  style={{
                    backgroundColor: 'white',
                    paddingLeft: 2,
                    paddingRight: 2,
                  }}
                  htmlFor="Name"
                >
                  Name
                </InputLabel>
                <Select
                  value={selectedGroupName}
                  onChange={(evt) => {
                    setSelectedGroupName(evt.target.value);
                  }}
                >
                  <MenuItem value={0} style={{ textAlign: 'center' }}>
                    None
                  </MenuItem>
                  {selectedGroup === 0 &&
                    selectedGroupItems &&
                    selectedGroupItems.map((itm) => (
                      <MenuItem
                        key={'menu-item-group-0-' + itm.id}
                        value={itm.manufacturer_id}
                        style={{ textAlign: 'center' }}
                      >
                        {itm.company_name}
                      </MenuItem>
                    ))}
                  {selectedGroup === 1 &&
                    selectedGroupItems &&
                    selectedGroupItems.map((itm) => (
                      <MenuItem
                        key={'menu-item-group-1-' + itm.id}
                        value={itm.id}
                        style={{ textAlign: 'center' }}
                      >
                        {itm.firstname + ' ' + itm.lastname}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
            <Typography variant="h6" className={classes.sectionReport}>
              Period
            </Typography>
            <div className={classes.formContainer}>
              <FormControl
                variant="outlined"
                style={{ width: '45%' }}
                className={classes.sectionReport}
              >
                <TextField
                  onChange={(e) => setReportStartDate(e.target.value)}
                  autoComplete=""
                  name="fromReport"
                  variant="outlined"
                  type="date"
                  value={reportStartDate}
                  required
                  fullWidth
                  id="fromReport"
                  label="From"
                  color="secondary"
                  InputLabelProps={{ shrink: true }}
                />
              </FormControl>
              <FormControl
                variant="outlined"
                style={{ width: '45%' }}
                className={classes.sectionReport}
              >
                <TextField
                  onChange={(e) => setReportEndDate(e.target.value)}
                  autoComplete=""
                  name="toReport"
                  variant="outlined"
                  type="date"
                  value={reportEndDate}
                  required
                  fullWidth
                  id="toReport"
                  label="To"
                  color="secondary"
                  InputLabelProps={{ shrink: true }}
                />
              </FormControl>
            </div>
            {ordersState.loadingReport ? (
              <CircularProgress size={25} className={classes.btnDownloadReport} />
            ) : (
              <button
                className={classes.btnDownloadReport}
                onClick={() =>
                  handleReportsDownload(
                    selectedGroup,
                    selectedGroupName,
                    reportStartDate,
                    reportEndDate,
                  )
                }
              >
                download
              </button>
            )}
          </div>
        </Modal>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={modalOpen}
          onClose={handleModalClose}
          className={classes.orderDetails}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <div className={classes.paper}>
            <div className={classes.editButton}>
              {uiState.orderProductsLoader ? (
                <CircularProgress size={25} className={classes.modalLoader} />
              ) : null}
              {editOption ? (
                <IconButton aria-label="save-button" onClick={handleEditSave}>
                  <Check />
                </IconButton>
              ) : selectedStatus.value === 'delivered' ||
                selectedStatus.value === 'open' ||
                selectedStatus.value === 'valid' ||
                selectedStatus.value === 'shipped' ? (
                <IconButton aria-label="edit-button" onClick={handleEditOpen}>
                  <Edit />
                </IconButton>
              ) : null}
            </div>
            <div className={classes.orderHeaders}>
              <div className={classes.orderID}>
                <Typography className={classes.orderAttributes}>
                  {`Kamioun ID: ${orderData.increment_id}`}
                </Typography>
              </div>
              <div className={classes.orderID}>
                <Typography className={classes.orderAttributes}>Shop Name:</Typography>
                <Typography className={classes.orderInfos}>
                  {`${orderData.customer_firstname} ${orderData.customer_lastname}`}
                </Typography>
              </div>
              {editOption && selectedStatus.value !== 'delivered' ? (
                <div className={classes.orderEdit}>
                  <TextField
                    onChange={(e) => handleDeliveryDateChange(e.target.value)}
                    autoComplete=""
                    name="dileverydate"
                    variant="outlined"
                    type="date"
                    value={deliveryDate}
                    required
                    fullWidth={false}
                    id="dileverydate"
                    label="Delivery date"
                    color="secondary"
                    InputProps={{
                      inputProps: { min: moment().format('YYYY-MM-DD') },
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
              ) : (
                <div className={classes.orderID}>
                  <Typography className={classes.orderAttributes}>Delivery Date:</Typography>
                  <Typography className={classes.orderInfos}>
                    {orderData && orderData.extension_attributes.delivery_date}
                  </Typography>
                </div>
              )}
              <div className={classes.orderID}>
                <Typography className={classes.orderAttributes}>Order Total:</Typography>
                <Typography className={classes.orderInfos}>
                  {orderData && orderData.base_grand_total?.toFixed(3)}
                </Typography>
              </div>
            </div>
            <Divider />
            <div className={classes.productTable}>
              <Scrollbars style={{ height: isSmallScreen ? 300 : 500 }}>
                <div>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">Code SKU</TableCell>
                          <TableCell align="left">Designation</TableCell>
                          <TableCell align="left">Shipped</TableCell>
                          <TableCell align="right">PCB</TableCell>
                          <TableCell align="right">Quantity</TableCell>
                          <TableCell align="right">Prix Total</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {modalOpen && orderData
                          ? orderData.items.map((row) => (
                              <TableRow key={row.sku}>
                                <TableCell className={classes.noBrder}>{row.sku}</TableCell>
                                <TableCell className={classes.noBrder}>{row.name}</TableCell>
                                {editOption ? (
                                  <TableCell className={classes.shippedInput} align="right">
                                    <TextField
                                      id="standard-number"
                                      type="number"
                                      onChange={(event) =>
                                        handleShippedInputChange(
                                          row.sku,
                                          event.target.value,
                                          row.qty_ordered,
                                        )
                                      }
                                      value={shippedValues[row.sku]}
                                      defaultValue={row.weight}
                                      InputProps={{
                                        inputProps: { min: 0, max: row.qty_ordered },
                                      }}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                    />
                                  </TableCell>
                                ) : (
                                  <TableCell className={classes.noBrder}>{row.weight}</TableCell>
                                )}
                                <TableCell className={classes.noBrder} align="right">
                                  {row.qty_ordered}
                                </TableCell>
                                <TableCell className={classes.noBrder} align="right">
                                  {productsState.orderProducts &&
                                  productsState.orderProducts.find((item) => item.sku == row.sku)
                                    ? productsState.orderProducts
                                        .find((item) => item.sku == row.sku)
                                        .custom_attributes.find(
                                          (item) => item.attribute_code == 'qty_pcb',
                                        ).value * row.qty_ordered
                                    : '-'}
                                </TableCell>
                                <TableCell className={classes.noBrder} align="right">
                                  {getTotal(row)?.toFixed(3)}
                                </TableCell>
                              </TableRow>
                            ))
                          : null}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </Scrollbars>
              {orderData && orderData.extension_attributes.agent_comment && (
                <>
                  <Divider />
                  <div className={classes.agentComment}>
                    <Typography className={classes.orderInfos}>
                      {orderData.extension_attributes.agent_comment}
                    </Typography>
                  </div>
                </>
              )}
            </div>
          </div>
        </Modal>
        <Menu
          id="fade-menu"
          anchorEl={menuAnchorEl}
          keepMounted
          open={menuOpen}
          onClose={() => setMenuAnchorEl(false)}
          TransitionComponent={Fade}
        >
          <MenuItem key={'picklist'} onClick={() => handleGenratePicklist()}>
            Generate Picklist
          </MenuItem>
          {selectedStatus && rowsData.length > 0 && selectedStatus.next ? (
            <MenuItem key={'setTo1'} onClick={() => handleMenuItemClick(selectedStatus.next)}>
              Set to{' '}
              {selectedStatus.next === 'delivered'
                ? 'Delivered & Paid'
                : selectedStatus.next === 'shipped'
                  ? 'Ready to ship'
                  : selectedStatus.next === 'Being_delivered'
                    ? 'Being delivered'
                    : selectedStatus.next}
            </MenuItem>
          ) : null}
          {selectedStatus.value === 'shipped' && rowsData.length > 0 ? (
            <MenuItem key={'setTo2'} onClick={() => handleMenuItemClick('unpaid')}>
              Set to Unpaid
            </MenuItem>
          ) : null}
          {selectedStatus && rowsData.length > 0 && selectedStatus.prev ? (
            <MenuItem key={'setBack'} onClick={() => handleMenuItemClick(selectedStatus.prev)}>
              Set back to{' '}
              {selectedStatus.prev === 'shipped' ? 'Ready to ship' : selectedStatus.prev}
            </MenuItem>
          ) : null}
          {selectedStatus && rowsData.length > 0 && selectedStatus.cancel ? (
            <MenuItem key={'cancel'} onClick={() => handleMenuItemClick('canceled')}>
              Cancel
            </MenuItem>
          ) : null}
          <MenuItem key={'printBls'} onClick={() => handlePrintOrderSummaries()}>
            Print BLs
          </MenuItem>
          <MenuItem
            key={'dispatch'}
            onClick={() => {
              history.push('/app/dispatch');
            }}
          >
            Manage milk-runs
          </MenuItem>
        </Menu>
        <Paper className={classes.widgetRoot}>
          <MuiThemeProvider theme={theme}>
            <MaterialTable
              tableRef={tableRef}
              title={
                <div
                  className={classes.rowDirection}
                  style={{
                    flexDirection: isSmallScreen ? 'column' : 'row',
                  }}
                >
                  <Typography
                    className={classes.title}
                    style={{ fontSize: isSmallScreen ? '0.9em' : '1.5em' }}
                  >
                    Orders
                  </Typography>
                  <Button
                    ref={anchorRef}
                    className={classes.status}
                    style={{
                      fontSize: isSmallScreen ? '0.9em' : '1.4em',
                      right: isSmallScreen ? 30 : 0,
                    }}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={() => setOpen(!open)}
                  >
                    {status.find((item) => item.value == ordersState.selectedFilter).field}
                  </Button>
                  <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    transition
                    style={{ zIndex: 100 }}
                  >
                    <Paper className={classes.widgetRoot}>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList
                          autoFocusItem={open}
                          id="menu-list-grow"
                          onKeyDown={handleListKeyDown}
                        >
                          {status.map((item) => {
                            return (
                              <MenuItem
                                key={item.value}
                                onClick={() => handleStatusChange(item.value)}
                              >
                                {item.field}
                              </MenuItem>
                            );
                          })}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Popper>
                </div>
              }
              columns={[
                {
                  title: 'ID',
                  field: 'increment_id',
                  sorting: true,
                  type: 'numeric',
                  align: 'center',
                  width: '100%',
                  cellStyle: { color: '#263238' },
                  headerStyle: columnHeader,
                },
                {
                  title: 'Customer',
                  field: 'customer',
                  type: 'string',
                  sorting: true,
                  align: 'center',
                  cellStyle: { color: '#263238', textDecoration: 'underline' },
                  headerStyle: columnHeader,
                },
                {
                  title: 'Total (TND)',
                  field: 'total',
                  type: 'numeric',
                  sorting: true,
                  align: 'center',
                  cellStyle: { color: '#263238' },
                  headerStyle: columnHeader,
                  customFilterAndSearch: (filter, rowData, columnDef) => {
                    let isFound = false;
                    const orderProducts = rowData.items;
                    orderProducts.forEach((item) => {
                      if (
                        item.sku.search(filter) >= 0 ||
                        item.name.toLowerCase().search(filter.toLowerCase()) >= 0
                      ) {
                        isFound = true;
                      }
                    });
                    return isFound;
                  },
                },
                {
                  title: 'Delivery Date',
                  field: 'extension_attributes.delivery_date',
                  sorting: true,
                  type: 'date',
                  align: 'center',
                  type: 'date',
                  dateSetting: 'DD/MM/YYYY',
                  cellStyle: { color: '#263238' },
                  headerStyle: columnHeader,
                  customFilterAndSearch: (filter, rowData, columnDef) => {
                    let isFound = false;
                    if (rowData.extension_attributes.delivery_date) {
                      let delivery_date = moment(rowData.extension_attributes.delivery_date);
                      if (
                        delivery_date &&
                        (delivery_date.format('DD/MM/YYYY').search(filter) >= 0 ||
                          delivery_date.format('DD/MM/YY').search(filter) >= 0 ||
                          delivery_date.format('DD/M/YY').search(filter) >= 0 ||
                          delivery_date.format('DD/M/YYYY').search(filter) >= 0 ||
                          delivery_date.format('DD/MM/YY').search(filter) >= 0 ||
                          delivery_date.format('D/M/YY').search(filter) >= 0 ||
                          delivery_date.format('D/M/YYYY').search(filter) >= 0 ||
                          delivery_date.format('D/MM/YY').search(filter) >= 0 ||
                          delivery_date.format('D/MM/YYYY').search(filter) >= 0)
                      ) {
                        isFound = true;
                      }
                    }
                    return isFound;
                  },
                },
                {
                  title: 'Delivery Agent',
                  field: 'extension_attributes.delivery_agent',
                  sorting: false,
                  align: 'center',
                  emptyValue: '-',
                  cellStyle: { color: '#263238' },
                  headerStyle: columnHeader,
                },
                {
                  title: 'Delivery Status',
                  field: 'extension_attributes.delivery_status',
                  sorting: false,
                  align: 'center',
                  render: (rowData) =>
                    rowData.extension_attributes.delivery_status && (
                      <div
                        className={classes.deliveryStatus}
                        style={{
                          backgroundColor:
                            deliveryStatus[rowData.extension_attributes.delivery_status]?.color,
                        }}
                      >
                        {rowData.extension_attributes.delivery_status.toLowerCase()}
                      </div>
                    ),
                  cellStyle: { color: '#263238' },
                  headerStyle: columnHeader,
                  emptyValue: '-',
                },
                {
                  title: '',
                  field: '',
                  sorting: false,
                  align: 'center',
                  render: (rowData) => (
                    <div className={classes.iconsContainer}>
                      {rowData.customer_id == rowData.extension_attributes.creator_id ? (
                        rowData.extension_attributes.verified ? (
                          <img className={classes.logotypeIcon} src={logo} alt="kamioun logo" />
                        ) : (
                          <img
                            className={classes.logotypeIcon}
                            src={redLogo}
                            alt="kamioun logo red"
                          />
                        )
                      ) : null}
                    </div>
                  ),
                },
              ]}
              icons={{
                ResetSearch: () =>
                  inSearch ? (
                    <IconButton
                      name={'close'}
                      style={{
                        color: 'grey',
                      }}
                    >
                      <Close />
                    </IconButton>
                  ) : (
                    ''
                  ),
              }}
              data={ordersState.orders.items}
              isLoading={uiState.isLoading}
              options={{
                sorting: true,
                actionsColumnIndex: 6,
                selection: true,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [25, 50, 100],
                pageSize: 25,
                searchFieldStyle: {
                  color: !inSearch ? '#c6cacc' : 'grey',
                  height: '50px',
                  width: 'auto',
                  borderRadius: '7px',
                  marginTop: '10px',
                  border: 'solid 1px #c6cacc',
                },
                searchFieldVariant: 'outlined',
                search: !!uiState.isLoadingComplete,
              }}
              localization={{
                toolbar: {
                  searchPlaceholder: 'Search for a product',
                },
                header: {
                  actions: '',
                },
              }}
              actions={[
                {
                  icon: () => <CircularProgress size={25} className={classes.modalLoader} />,
                  isFreeAction: true,
                  hidden: !!uiState.isLoadingComplete,
                  tooltip: 'Orders loading sequentially',
                },
                {
                  icon: () => <PdfIcon className={classes.pdfIcon} />,
                  tooltip: 'summary',
                  position: 'row',
                  onClick: (event, rowData) => generateOrderSummary(rowData.entity_id),
                },
                {
                  icon: () => (
                    <img
                      style={{
                        height: isSmallScreen ? null : 23,
                        width: isSmallScreen ? null : 23,
                        margin: isSmallScreen ? 0 : '12px 10px 10px 15px',
                      }}
                      src={pdfIcon}
                      alt="logo"
                    />
                  ),
                  tooltip: 'Download Reports',
                  isFreeAction: true,
                  hidden: !uiState.isLoadingComplete,
                  disabled: !uiState.isLoadingComplete,
                  onClick: () => setModalAgentReportOpen(true),
                },
                {
                  icon: () => <MoreVertIcon size={20} />,
                  tooltip: 'Actions Menu',
                  isFreeAction: true,
                  onClick: (event) => {
                    setMenuAnchorEl(event.currentTarget);
                  },
                },
                {
                  icon: () => (
                    <img
                      style={{ height: 23, width: 23, margin: '12px 10px 10px 15px' }}
                      src={pdfIcon}
                      alt="logo"
                    />
                  ),
                  tooltip: 'Download Reports',
                  onClick: (event) => setModalAgentReportOpen(true),
                },
                {
                  icon: () => <MoreVertIcon size={20} />,
                  tooltip: 'Actions Menu',
                  onClick: (event) => {
                    setMenuAnchorEl(event.currentTarget);
                  },
                },
              ]}
              onRowClick={(event, rowData) => {
                setOrderData(rowData);
                handleModalOpen(rowData);
              }}
              onSelectionChange={(rows) => handleSelectionChange(rows)}
            />
          </MuiThemeProvider>
        </Paper>
      </Grid>
    </Grid>
  </div>
  
  );
}
